import gql from "graphql-tag";

export const GET_LIST_FOUNDATIONS = gql`
  {
    id
    title
    description
    title_en
    description_en
    cover
    link
    shown
    created_at
    updated_at
    dark_text
  }
`;

export const GET_ONE_FOUNDATION = gql`
  {
    id
    title
    description
    title_en
    description_en
    cover
    link
    shown
    created_at
    updated_at
    dark_text
  }
`;

export const GET_LIST_EVENTS = gql`
  {
    id
    name
    description
    date
    cover
    link
    name_en
    description_en
    cover_en
    shown
    video_id
  }
`;

export const GET_ONE_EVENT = gql`
  {
    id
    name
    name_en
    description
    description_en
    date
    cover
    cover_en
    link
    shown
    video_id
  }
`;

export const GET_LIST_USER = gql`
  {
    id
    name
    email
    phone
    country
    church
    region
    photo_id
    fcm_token
    photo {
      photo_url
    }
    created_at
    gender
    access_level
    coach_id
    connect_id
    coach_connect_id
    connect_group_list {
      name
    }
    banned
    role_id
  }
`;

export const GET_ONE_USER = gql`
  {
    id
    name
    email
    phone
    country
    church
    photo {
        id
        photo_url
    }
    created_at
    gender
    access_level
    coach_id
    connect_id
    coach_connect_id
    connect_group_list {
      name
    }
    banned
    role_id
  }
`;


export const GET_LIST_VIDEO = gql`
  {
    id
    url
    title
    title_en
    description
    description_en
    banner_title
    banner_title_en
    duration
    filter_id
    status
    snippet
    snippet_en
    created_at
    created_date
    premiere_at
    zoom
    online_foyer_time
    shown
    shown_banner
    donation_button_visible
    is_banner
    btn_text
    btn_text_en
    position
  }
`;

export const GET_ONE_VIDEO = gql`
  {
    id
    url
    title
    title_en
    description
    description_en
    banner_title
    banner_title_en
    duration
    filter_id
    status
    snippet
    snippet_en
    created_at
    created_date
    premiere_at
    zoom
    online_foyer_time
    shown
    shown_banner
    donation_button_visible
    is_banner
    btn_text
    btn_text_en
    position
  }
`;

export const GET_LIST_CONNECT_GROUP = gql`
  {
    id
    status
    name
    email
    country
    region
    user_id
    created_at
    updated_at
    phone
    group_id
    joined
  }
`;

export const GET_ONE_CONNECT_GROUP = gql`
  {
    id
    status
    name
    email
    country
    region
    user_id
    created_at
    updated_at
    phone
    group_id
    joined
  }
`;

export const GET_LIST_VOLONTIER_GROUP = gql`
  {
    id
    status
    name
    email
    country
    region
    talents
    user_id
    volunteer_teams
    created_at
    updated_at
    phone
  }
`;

export const GET_ONE_VOLONTIER_GROUP = gql`
  {
    id
    status
    name
    email
    country
    region
    talents
    user_id
    volunteer_teams
    created_at
    updated_at
    phone
  }
`;

export const GET_LIST_CONNECT_GROUP_LIST = gql`
  {
    id
    name
    logo
    name_en
    main_coach_id
    created_at
  }
`;

export const GET_ONE_CONNECT_GROUP_LIST = gql`
  {
    id
    name
    logo
    name_en
    main_coach_id
    created_at
  }
`;

export const GET_LIST_FILTER = gql`
  {
    id
    type
    name_ua
    name_en
    filter_icon
    is_icon_shown
    is_access_unregister_user
    index
    created_at
    position
  }
`;

export const GET_ONE_FILTER = gql`
  {
    id
    type
    name_ua
    name_en
    filter_icon
    is_access_unregister_user
    is_icon_shown
    index
    created_at
    position
  }
`;

export const GET_LIST_MEETING = gql`
  {
    id
    name
    name_en
    address
    address_en
    coordinates
    created_at
    updated_at
  }
`;

export const GET_ONE_MEETING = gql`
  {
    id
    name
    name_en
    address
    address_en
    coordinates
    created_at
    updated_at
  }
`;

export const GET_LIST_MEETING_REQUESTS = gql`
  {
    id
    meeting_id
    user_id
    address
    birthday
    church
    city
    country
    email
    family
    status
    phone
    name
    hospitable
    message
    created_at
    updated_at
    meeting {
      name
    }
  }
`;

export const GET_ONE_MEETING_REQUESTS = gql`
  {
    id
    meeting_id
    user_id
    address
    birthday
    church
    city
    country
    email
    family
    status
    phone
    name
    hospitable
    message
    created_at
    updated_at
    meeting {
      name
    }
  }
`;

export const GET_LIST_PRAYER = gql`
  {
    id
    text
    type
    user_id
    isAnon
    created_at
    updated_at
    press_count
    is_archived
  }
`;

export const GET_ONE_PRAYER = gql`
  {
    id
    text
    type
    user_id
    isAnon
    created_at
    updated_at
    press_count
    is_archived
  }
`;

export const GET_LIST_VOLUNTEER_TEAMS = gql`
  {
    id
    name
    name_en
    description
    description_en
    cover_small
    cover_small_en
    cover_large
    cover_large_en
    created_at
    covers_description
  }
`;

export const GET_ONE_VOLUNTEER_TEAMS = gql`
  {
    id
    name
    name_en
    description
    description_en
    cover_small
    cover_small_en
    cover_large
    cover_large_en
    created_at
    covers_description
  }
`;

export const GET_LIST_ACCESS_LEVEL = gql`
  {
    id
    name
    filter_ids
    created_at
  }
`;

export const GET_ONE_ACCESS_LEVEL = gql`
  {
    id
    name
    filter_ids
    created_at
  }
`;

export const GET_LIST_ROLES = gql`
  {
    id
    access_level_id
    table_id
    role_name_id
    is_default
  }
`;

export const GET_ONE_ROLES = gql`
  {
    id
    access_level_id
    table_id
    role_name_id
    is_default
  }
`;
